<template>
  <div>
    <div class="top-box12">
      <div class="left-box">
        <p class="p-1">基本信息</p>
        <div class="nr-box123">
          <div class="nr-1">
            <img class="img" :src="orgInfo.mechanism_logo" alt="" />
            <p>机构logo</p>
          </div>

          <div class="nr">
            <p>机构名称：{{ orgInfo.mechanism_name }}</p>
            <p class="nr-p">机构类型：{{ orgInfo.mechanism_addr }}</p>
            <p>机构地址：{{ orgInfo.mechanism_addr }}</p>
          </div>
          <div class="nr">
            <p>负责人姓名：{{ orgInfo.contacts }}</p>
            <p class="nr-p">负责人电话：{{ orgInfo.mechanism_telephone }}</p>
            <p>联系人电话：{{ orgInfo.contact_telephone }}</p>
          </div>
          <div class="nr">
            <p>支付宝账号：{{ orgInfo.payee_logon_id }}</p>
            <p class="nr-p">机构等级：</p>
            <p>
              状态：
              <span v-if="orgInfo.status == 1" style="color: #23c3c4"
                ><span> 待审核</span>
              </span>
              <span v-else-if="orgInfo.status == 2" style="color: #32cd32"
                >已通过</span
              >
              <span v-else-if="orgInfo.status == 3" style="color: #ff6262"
                >已拒绝</span
              >
              <span v-else-if="orgInfo.status == 4" style="color: #ff6262"
                >注销</span
              >
              <span v-else-if="orgInfo.status == 5" style="color: #ff6262"
                >冻结</span
              >
            </p>
          </div>
        </div>
      </div>
      <div
        style="width: 15%; height: 220px; line-height: 200px; text-align: right"
      >
        <el-button style="margin-right: 20px" type="warning" plain
          >修改信息</el-button
        >
      </div>
    </div>
    <div class="Detailedpicture">
      <div class="TP-1">
        <P>营业执照</P>
        <img class="img" :src="orgInfo.support_means" alt="" />
      </div>
      <div class="TP-1">
        <P>机构门面</P>
        <img class="img" :src="orgInfo.facade_view " alt="" />
      </div>
      <div class="TP-2">
        <p>其它</p>
        <div class="qt-tp">
          <img class="img" src="pic" alt="" v-for="(pic, index) in orgInfo.environment_pic.split(',')" :key="index"/>
        </div>
      </div>
    </div>
    <div class="di-box">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane label="课程" name="0">
          <el-table :data="couList" v-loading="is_loading">
            <el-table-column label="课程id" prop="id"> </el-table-column>
            <el-table-column label="课程标题" prop="title"> </el-table-column>
            <el-table-column
              label="课程总节数"
              prop="course_num"
            ></el-table-column>
            <el-table-column
              label="课程购买数"
              prop="pay_num"
            ></el-table-column>
            <el-table-column
              label="课程单节价格"
              prop="price_per_session"
            ></el-table-column>
            <el-table-column label="课程总价" prop="">
              <template slot-scope="scope">
                {{ scope.row.course_num * scope.row.price_per_session }}
              </template>
            </el-table-column>
            <!-- todo -->
            <el-table-column
              label="上架时间"
              prop="create_time"
            ></el-table-column>
            <el-table-column label="状态" prop="status"></el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="scope">
                <div style="display: flex">
                  <!-- <el-button size="small">下架</el-button> -->
                  <el-button size="small" @click="toCouDetails(scope.row.id)"
                    >查看详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changeCouListCurrentPage"
            :current-page="couListCurrentPage"
            :total="couTotal"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="场地" name="1">
          <el-table :data="placeList" v-loading="is_loading">
            <el-table-column label="课程id" prop="id"> </el-table-column>
            <el-table-column label="课程标题" prop="title"> </el-table-column>
            <el-table-column
              label="课程总节数"
              prop="course_num"
            ></el-table-column>
            <el-table-column
              label="课程购买数"
              prop="pay_num"
            ></el-table-column>
            <el-table-column
              label="课程单节价格"
              prop="price_per_session"
            ></el-table-column>
            <el-table-column label="课程总价" prop="">
              <template slot-scope="scope">
                {{ scope.row.course_num * scope.row.price_per_session }}
              </template>
            </el-table-column>
            <!-- todo -->
            <el-table-column
              label="上架时间"
              prop="create_time"
            ></el-table-column>
            <el-table-column label="状态" prop="status"></el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="scope">
                <div style="display: flex">
                  <!-- <el-button size="small">下架</el-button> -->
                  <el-button size="small" @click="toCouDetails(scope.row.id)"
                    >查看详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changePlaceListCurrentPage"
            :current-page="placeListCurrentPage"
            :total="placeTotal"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="结算" name="2">
          <el-table
            :data="RefundList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
          >
          <el-table-column label="流水号" prop="flowing_no"></el-table-column>
        <el-table-column label="机构id" prop="mechanism_id"></el-table-column>
        <!-- <el-table-column label="机构名称">
          <template slot-scope="{row}">
            <div v-if="MecList[row.mechanism_id]">
              {{MecList[row.mechanism_id].mechanism_name}}
            </div>
            <div></div>
          </template>
        </el-table-column> -->
        <el-table-column label="金额" prop="amount"></el-table-column>
        <el-table-column
          label="下单时间"
          prop="finished_time"
        ></el-table-column>
        <el-table-column label="支付描述" prop="rcharge_type"></el-table-column>
        <el-table-column
          label="支付描述"
          prop="rcharge_abstract"
        ></el-table-column>
        <el-table-column label="支付状态">
          <template slot-scope="{ row }">
            <div v-if="row.finished" style="color: green">已支付</div>
            <div v-else>未支付</div>
          </template>
        </el-table-column>
          </el-table>
          <el-pagination
            :current-page="RefundListCurrentPage"
            :total="RefundTotal"
            @current-change="changeRefundListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="教练" name="3">
          <el-table :data="teaList" v-loading="is_loading">
            <el-table-column label="姓名" prop="full_name"></el-table-column>
            <el-table-column label="头像" prop="userInfo.avator">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.photo"
                  alt=""
                  style="width: 3rem; min-height: 3rem"
                />
              </template>
            </el-table-column>
            <el-table-column label="教龄" prop="master_age"></el-table-column>
            <el-table-column label="科目" prop="categories"></el-table-column>
            <el-table-column label="联系方式" prop="mobile"></el-table-column>
            <el-table-column label="状态" prop="status"></el-table-column>
            <el-table-column label="住址" prop="address"></el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="danger"
                  v-if="scope.row.status == 2"
                  @click="unshelve(scope.row.id)"
                  >下架</el-button
                >
                <el-button size="small" @click="toTeaDetails(scope.row.id)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="teaListCurrentPage"
            :total="teaTotal"
            @current-change="changeTeaListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="订单" name="4">
          <el-table
            :data="payList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
            v-loading="is_loading"
          >
            <el-table-column
              label="交易单号"
              width="250px"
              prop="flowing_no"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              prop="map.masterSetPriceEntity.title"
            ></el-table-column>
            <el-table-column label="订单总金额" prop="amount"></el-table-column>
            <el-table-column
              label="姓名"
              prop="map.userinfo.nick_name"
            ></el-table-column>
            <el-table-column
              label="手机号"
              prop="map.userinfo.mobile"
            ></el-table-column>
            <el-table-column
              label="交易时间"
              prop="rcharge_time"
            ></el-table-column>
            <el-table-column label="订单方式" prop="pay_type"></el-table-column>
          </el-table>
          <el-pagination
            :current-page="payListCurrentPage"
            :total="payTotal"
            @current-change="changePayListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="课程表" name="5">
          <el-table :data="spyList" v-loading="is_loading">
            <el-table-column label="上课日期/星期" prop="start_time">
              <template slot-scope="scope">
                {{ getWeekday(scope.row.start_time) }}
              </template>
            </el-table-column>
            <el-table-column label="上课时间段" prop="end_time">
              <template slot-scope="scope">
                {{
                  scope.row.start_time.split(" ")[1] +
                  "-" +
                  scope.row.end_time.split(" ")[1]
                }}
              </template>
            </el-table-column>
            <el-table-column label="班级名称" prop="class_name">
            </el-table-column>
            <el-table-column label="课程名称" prop="title"></el-table-column>
            <el-table-column
              label="科目"
              prop="map.masterSetPriceEntity.categories"
            >
            </el-table-column>
            <el-table-column label="课时" prop="length_of_lesson">
            </el-table-column>
            <!-- todo -->
            <el-table-column label="上课教室" prop="classroom">
            </el-table-column>
            <el-table-column label="主教/助教" prop="map.master_name">
            </el-table-column>
            <el-table-column label="学生人数" prop="map.studentSoonCount">
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="spyListCurrentPage"
            :total="spyTotal"
            @current-change="changeSpyListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
  
      <!-- 新增 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="724px"
        title="新增"
      >
        <div style="display: flex; color: #434343; font-size: 14px">
          <div style="width: 45%">
            <div style="text-align: right">
              <samp style="color: #f56c6c">*</samp
              ><samp style="margin-right: 10px">课程标题</samp
              ><el-input v-model="nav.firstNav"></el-input>
            </div>
            <div style="text-align: right; margin: 30px 0">
              <samp style="color: #f56c6c">*</samp
              ><samp style="margin-right: 10px">课程购买数</samp
              ><el-input v-model="nav.firstNav"></el-input>
            </div>
            <div style="text-align: right">
              <samp style="color: #f56c6c">*</samp
              ><samp style="margin-right: 10px">课程总价</samp
              ><el-input v-model="nav.firstNav"></el-input>
            </div>
          </div>
          <div style="width: 50%">
            <div style="text-align: right">
              <samp style="color: #f56c6c">*</samp
              ><samp style="margin-right: 10px">课程总结数</samp
              ><el-input v-model="nav.firstNav"></el-input>
            </div>
            <div style="text-align: right; margin: 30px 0">
              <samp style="color: #f56c6c">*</samp
              ><samp style="margin-right: 10px">课程单节价格</samp
              ><el-input v-model="nav.firstNav"></el-input>
            </div>
            <div style="text-align: right">
              <samp style="color: #f56c6c">*</samp
              ><samp style="margin-right: 10px">上架时间</samp
              ><el-input v-model="nav.firstNav"></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            style="background-color: #ff7e00; color: #ffffff"
            @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dd: "",
      nav: { firstNav: "机构管理中心", secondNav: "机构详情" },
      orgInfo: {
        environment_pic: "",
        facade_view: "",
        support_means: "",
        mechanism_logo: "",
      },
      is_loading: true,
      activeName: "0",

      couList: [],
      couTotal: 0,
      couListCurrentPage: 1,

      placeList: [],
      placeTotal: 0,
      placeListCurrentPage: 1,

      teaList: [],
      teaTotal: 0,
      teaListCurrentPage: 1,

      RefundList: [],
      RefundTotal: 0,
      RefundListCurrentPage: 1,

      payList: [],
      payTotal: 0,
      payListCurrentPage: 1,

      stuList: [],
      stuTotal: 0,
      stuListCurrentPage: 1,

      spyList: [],
      spyTotal: 0,
      spyListCurrentPage: 1,

      dialogVisible:false
      // unFinishedCourse:[],
    };
  },
  updated() {
    this.dd = window.screen.width < 768 ? "width:100%" : "width:25%";
  },
  methods: {
    splitImgs(urlString) {
      return urlString ? urlString.split(",") : [];
    },
    getOrgPayList() {
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            finished: true,
            mechanism_id: this.$route.query.mecID,
          },
        })
        .then((res) => {
          console.log(res);
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    getOrgDetails() {
      let url = "/user/mastermechanism/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.$route.query.mecID,
          },
        })
        .then((res) => {
          // console.log(res);
          this.orgInfo = res.data.data;
        });
    },
    getWeekday(date) {
      let a = new Date(date).getDay();
      let weekday =
        a == 0
          ? "星期天"
          : a == 1
          ? "星期一"
          : a == 2
          ? "星期二"
          : a == 3
          ? "星期三"
          : a == 4
          ? "星期四"
          : a == 5
          ? "星期五"
          : "星期六";
      return date.split(" ")[0] + "/" + weekday;
    },
    getOrgCouList() {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: "mechanism_offline",
            // is_cooperation: true,
            pageSize: 10,
            currentPage: this.currentPage,
            mechanism_id: this.$route.query.mecID,
            contact_telephone: this.contact_telephone
              ? this.contact_telephone
              : null,
            contacts: this.contacts ? this.contacts : null,
            categories: "体育运动",
            // source:'体育宝',
            categories_child: this.categories_child
              ? this.categories_child
              : null,
          },
        })
        .then((res) => {
          // console.log(res);
          this.couList = res.data.data.rows;
          this.couTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgPlaceList() {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: "场馆",
            // is_cooperation: true,
            pageSize: 10,
            currentPage: this.currentPage,
            mechanism_id: this.$route.query.mecID,
            contact_telephone: this.contact_telephone
              ? this.contact_telephone
              : null,
            contacts: this.contacts ? this.contacts : null,
            categories: "体育运动",
            // source:'体育宝',
            categories_child: this.categories_child
              ? this.categories_child
              : null,
          },
        })
        .then((res) => {
          // console.log(res);
          this.placeList = res.data.data.rows;
          this.placeTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    changeCouListCurrentPage(v) {
      this.is_loading = true;
      this.couListCurrentPage = v;
      this.getOrgCouList();
    },
    changePlaceListCurrentPage(v) {
      this.is_loading = true;
      this.placeListCurrentPage = v;
      this.getOrgplaceList();
    },
    changeTeaListCurrentPage(v) {
      this.is_loading = true;
      this.teaListCurrentPage = v;
      this.getOrgTeaList();
    },
    changeRefundListCurrentPage(v) {
      this.is_loading = true;
      this.RefundListCurrentPage = v;
      this.getOrgRefundList();
    },
    changePayListCurrentPage(v) {
      this.is_loading = true;
      this.payListCurrentPage = v;
      this.getOrgPayList();
    },
    changeStuListCurrentPage(v) {
      this.is_loading = true;
      this.stuListCurrentPage = v;
      this.getOrgStuList();
    },
    changeSpyListCurrentPage(v) {
      this.is_loading = true;
      this.spyListCurrentPage = v;
      this.getOrgSpyList();
    },
    getOrgTeaList() {
      let url = "/user/masterInfo/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            mechanism_id: this.$route.query.mecID,
            // status:2,
            pageSize: 10,
            currentPage: this.teaListCurrentPage,
            type: "教练",
          },
        })
        .then((res) => {
          this.teaList = res.data.data.rows;
          this.teaTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgStuList() {
      let url = "/user/userStudyCard/querySchoolStudentList";
      this.$axios
        .get(url, {
          params: {
            mechanism_id: this.$route.query.mecID,
            pageSize: 10,
            currentPage: this.stuListCurrentPage,
            type: "mechanism_offline",
          },
        })
        .then((res) => {
          this.stuList = res.data.data.rows;
          this.stuTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgRefundList() {
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.refundCurrentPage,
            mechanism_id: this.orgInfo.id,
            finished: true,
          },
        })
        .then((res) => {
          this.stuList = res.data.data.rows;
          this.stuTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgSpyList() {
      let url = "/user/masterAppointment/querySchoolCourseList";
      this.$axios
        .get(url, {
          params: {
            mechanism_id: this.$route.query.id,
            is_teach_paypal: true,
            pageSize: 10,
            currentPage: this.spyListCurrentPage,
            type: "mechanism_offline",
            mechanism_id: this.$route.query.mecID,
            identity_type: "teach_paypal_course",
          },
        })
        .then((res) => {
          this.spyList = res.data.data.rows;
          this.spyTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    unshelve(id) {
      let url = "/user/masterInfo/update";
      this.$axios
        .post(url, {
          id: id,
          status: 1,
        })
        .then((res) => {
          this.is_loading = true;
          this.getOrgTeaList();
          this.$message({ type: "success", message: "下架成功!" });
        });
    },
    pass() {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.orgInfo.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getOrgDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    changeRecommand(status) {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.orgInfo.id,
          is_recommend: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getOrgDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    toCouDetails(id) {
      this.$router.push({
        name: "couDetails",
        query: { type: "mec", couID: id },
      });
    },
    toTeaDetails(id) {
      this.$router.push({
        name: "couchDetails",
        query: { type: "mec", couchID: id },
      });
    },
    toStuDetails(id) {
      this.$router.push({
        name: "stuDetails",
        query: { type: "mec", stuID: id },
      });
    },
    tabClick(tab, event) {
      this.is_loading = true;
      if (tab.index == 0) {
        this.couListCurrentPage = 1;
        this.getOrgCouList();
      } else if (tab.index == 1) {
        this.teaListCurrentPage = 1;
        this.getOrgPlaceList();
      } else if (tab.index == 2) {
        this.RefundListCurrentPage = 1;
        this.getOrgRefundList();
      } else if (tab.index == 3) {
        this.getOrgPayList();
      } else if (tab.index == 4) {
        this.stuListCurrentPage = 1;
        this.getOrgStuList();
      } else if (tab.index == 5) {
        this.spyListCurrentPage = 1;
        this.getOrgSpyList();
      }
    },
  },
  mounted() {
    this.getOrgDetails();
    this.getOrgCouList();
  },
};
</script>

<style scoped lang="less">
.activeName-1 {
  height: 108px;
  padding-left: 40px;
  display: flex;
  .activeName-box {
    width: 185px;
    font-size: 14px;
    color: #434343;
    .p-1 {
      margin: 24px 0 31px 0;
    }
  }
}

.top-box12 {
  background-color: #ffffff;
  height: 220px;
  display: flex;
  .left-box {
    width: 85%;
    height: 220px;
    .p-1 {
      font-size: 18px;
      color: #434343;
      margin-bottom: 29px;
      padding-left: 20px;
    }
    .nr-box123 {
      display: flex;
      .nr-1 {
        width: 184px;
        text-align: center;
        margin-right: 16px;
        .img {
          width: 100px;
          height: 100px;
        }
        > p {
          margin-top: 19px;
          font-size: 16px;
          color: #434343;
        }
      }
      .nr {
        flex: 1;
        font-size: 16px;
        color: #434343;
        .nr-p {
          margin: 30px 0;
        }
      }
    }
  }
}
.Detailedpicture {
  margin-top: 20px;
  height: 247px;
  display: flex;
  width: 100%;
  font-size: 18px;
  color: #434343;
  .TP-1 {
    width: 382px;
    background-color: #ffffff;
    margin-right: 20px;
    > p {
      margin: 20px 0 29px 20px;
    }
    .img {
      width: 300px;
      height: 150px;
      margin-left: 40px;
    }
  }
  .TP-2 {
    flex: 1;
    > p {
      margin: 20px 0 29px 20px;
    }
    background-color: #ffffff;
    .qt-tp {
      padding-left: 40px;
      .img {
        width: 240px;
        height: 150px;
        &:not(:last-child) {
          margin-right: 27px;
        }
      }
    }
  }
}
.di-box {
  margin-top: 20px;
  padding: 10px 20px 0 20px;
  background-color: #ffffff;
}
.cell {
  text-align: center;
}
</style>
